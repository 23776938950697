<template>
    <div class="gray-bg">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :loading-text="$t('加载中')"
                    :finished-text="$t('没有更多了')"
                    @load="onLoad"
            >
                <div class="article-card" v-for="item in dataList" :key="item.id" @click="showMaster(item)">
                    <div class="article-header">
                        {{item.pg_name}}
                    </div>
                    <div class="article-bottom">
                        <span>{{$t('价格')}}:{{item.price}}$</span>
                        <span>{{$t('数量')}}:{{item.number}}</span>
                        <span class="article-date">{{item.create_time}}</span>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        <van-popup
                v-model="show"
                position="bottom"
                custom-style="height: 30%;"
                @close="onClose"
                class="master-box"
        >
            <van-cell-group>

                <van-cell
                        :title="$t('详情')"
                        @click="showDetail"
                        center
                />
                <van-cell
                        :title="$t('发出询盘')"
                        @click="doEnquiry"
                        center
                />
                <van-cell
                        :title="$t('查看供应商')"
                        @click="showSupplier"
                        center
                />
            </van-cell-group>
        </van-popup>
        <supcompany ref="supcompany" :item="item"></supcompany>
        <goodsprice ref="goodsprice" :item="item"></goodsprice>
        <inquiry ref="inquiry" :item="item" ></inquiry>
    </div>
</template>

<script>
    import Inquiry from "../components/Inquiry.vue";
    import ShowSupplier from "../components/ShowSupplier";
    import GoodsPriceDetail from "../components/GoodsPriceDetail";
    export default {
        name: "GoodsPriceSup",
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                page:1,
                dataList: [],

                item:{},
                show:false,
                supshow:false,
                loadFlag:false,
            };
        },
        components: {
            inquiry: Inquiry,
            "supcompany": ShowSupplier,
            "goodsprice": GoodsPriceDetail,
        },
        beforeRouteLeave(to, from, next) {
            if (this.$refs.inquiry.show) {
                this.$refs.inquiry.toggleShow();
                next(false);
            }else if (this.$refs.supcompany.show) {
                this.$refs.supcompany.toggleShow();
                next(false);
            }else if (this.$refs.goodsprice.show) {
                this.$refs.goodsprice.toggleShow();
                next(false);
            }else {
                next();
            }
        },
        mounted() {

        },
        methods: {
            onClose(){
                this.show = false;
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                this.page = 1;
                this.dataList = [];
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                setTimeout(() => {
                    this.getPriceList();
                }, 1000);
            },
            getPriceList() {
                let that = this;
                this.$http.post("/v1/getPriceForCus", {
                    pg_name: '',
                    st_time: '',
                    ed_time: '',
                    page:this.page,
                }).then((res) => {
                    // 加载状态结束
                    that.loading = false;
                    //结束刷新
                    that.refreshing = false;
                    if (res.code == 200) {
                        this.dataList = this.dataList.concat(res.data.data);
                        if(that.page>res.data.last_page){
                            // 数据全部加载完成
                            that.finished = true;
                            that.page = res.data.last_page;
                        }else{
                            that.page = res.data.current_page+1;
                        }
                    }
                });

            },
            showMaster(obj){
                obj.goods = obj.pg_name;
                this.item = obj;
                this.show = true;
            },
            doEnquiry() {
                this.$refs.inquiry.goodsValue = this.item.pg_name;
                this.$refs.inquiry.supValue = this.item.sup_name;
                this.show = false;
                this.$refs.inquiry.toggleShow();
            },
            showDetail(){
                this.show = false;
                this.$refs.goodsprice.toggleShow();
            },
            showSupplier(){
                this.show = false;
                this.$refs.supcompany.toggleShow();
            },
        }
    }
</script>
<style scoped>
    .gray-bg{
        background-color: #fafafa;
        min-height: 90vh;
    }
    .article-card{
        position: relative;
        box-sizing: border-box;
        padding: 8px 16px;
        color: #323233;
        font-size: 12px;
        background-color: #FFFFFF;
        margin: 5px 0px;
        min-height: 80px;
    }
    .article-card span{
        padding: 0px 5px;
    }
    .article-body{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        width: 100vw;
    }
    .article-bottom{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        position: absolute;
        bottom: 5px;
        width: 100vw;
    }
    .article-date{
        margin-left: auto;
        padding-right: 20px !important;
    }
    .master-box .van-cell__title{
        text-align: center;
    }
    .van-cell__title{
        text-align: center;
    }
</style>