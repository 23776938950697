<template>
    <div
            v-if="show"
            class="transaction-search"
            :class="navBarShow ? 'transaction-search-no-wechat' : ''"
    >
        <van-field
                v-model="goodsValue"
                label-align="right"
                :label="$t('产品')"
                :placeholder="$t('产品')"
                @focus="goodsShow = true"
        >
            <template #right-icon>
                <van-icon name="close" @click.stop="clearPicker('goods')"/>
                <div v-if="goodsColumns.length > 0 && goodsShow" class="picker-main">
                    <div
                            v-for="(item, index) in goodsColumns"
                            :key="'goods' + index"
                            class="picker-list"
                            @click="selectPicker('goods', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field
                v-model="supValue"
                label-align="right"
                :label="$t('供应商')"
                :placeholder="$t('供应商')"
                @focus="supShow = true"
        >
            <template #right-icon>
                <van-icon name="close" @click.stop="clearPicker('sup')"/>
                <div v-if="supColumns.length > 0 && supShow" class="picker-main">
                    <div
                            v-for="(item, index) in supColumns"
                            :key="'sup' + index"
                            class="picker-list"
                            @click.stop="selectPicker('sup', item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </template>
        </van-field>
        <van-field
                label-align="right"
                v-model="goods_spec"
                clearable
                :label="$t('gy_goods_spec')"
                :placeholder="$t('gy_goods_spec')"
        />
        <van-field
                v-model="number"
                label-align="right"
                clearable
                :label="$t('dd_xj_02')"
                :placeholder="$t('dd_xj_02')"
        />
        <van-field
                v-model="certfile"
                label-align="right"
                clearable
                :label="$t('dd_xj_03')"
                :placeholder="$t('dd_xj_03')"
        />
        <van-field
                v-model="product_document"
                label-align="right"
                clearable
                :label="$t('产品文件')"
                :placeholder="$t('产品文件')"
        />
        <van-field
                v-model="packing"
                label-align="right"
                clearable
                :label="$t('packing')"
                :placeholder="$t('packing')"
        />
        <van-field name="radio" label-align="right" :label="$t('交易方式')">
            <template #input>
                <van-radio-group v-model="trade_method_price" direction="horizontal">
                    <van-radio name="FOB">FOB</van-radio>
                    <van-radio name="CIF">CIF</van-radio>
                    <van-radio name="CIP">CIP</van-radio>
                    <van-radio name="EXW">EXW</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field name="radio" label-align="right" :label="$t('交易方式')">
            <template #input>
                <van-radio-group v-model="trade_method_method" direction="horizontal">
                    <van-radio name="AIR">AIR</van-radio>
                    <van-radio name="SEA">SEA</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
                v-model="trade_method_port"
                label-align="right"
                clearable
                :label="$t('gy_cp_trade_port')"
                :placeholder="$t('gy_cp_trade_port')"
        />
        <van-field
                v-model="special"
                label-align="right"
                rows="2"
                autosize
                :label="$t('gy_special')"
                :placeholder="$t('gy_special')"
                type="textarea"
        />
        <van-field name="checkbox" label-align="right" :label="$t('自动询盘')">
            <template #input>
                <van-radio-group v-model="auto" direction="horizontal">
                    <van-checkbox v-model="auto.flag"></van-checkbox>
                </van-radio-group>
            </template>
        </van-field>
        <van-field
                v-model="auto.days"
                label-align="right"
                clearable
                :label="$t('周期天数')"
                :placeholder="$t('自动询盘周期天数')"
                type="number"
        />
        <van-field
                v-model="auto.times"
                label-align="right"
                clearable
                :label="$t('周期次数数')"
                :placeholder="$t('自动询盘周期次数')"
                type="number"
        />
        <div class="blank-box"></div>
        <van-button
                color="#5d74f2"
                class="transaction-search-btn"
                @click="doEnquiry"
        >{{ $t("提交") }}
        </van-button>
        <van-overlay :show="loadFlag">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="34px" color="#FFFFFF" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import utils from "../utils/utils";
    import {Toast} from "vant";

    export default {
        name: "Inquiry",
        data() {
            return {
                loadFlag:false,
                id: "",
                goodsValue: "",
                supValue: "",
                goods_spec: "",
                number: "",
                certfile: "",
                product_document: "",
                packing: "",
                trade_method_price: "",
                trade_method_method: "",
                trade_method_port: "",
                special: "",
                //自动询盘参数
                auto: {
                    flag: 0,
                    days: '',
                    times: '',
                },

                navBarShow: false, // 是否有顶部导航栏
                show: false, // 搜索框显示
                supList: [], // 供应商列表
                goodsList: [], // 产品列表
                goodsColumns: [], // 产品选择器显示数据
                supColumns: [], // 供应商选择器显示数据
                goodsShow: false, // 产品下拉框显示隐藏
                supShow: false, // 供应商下拉框显示隐藏
            };
        },
        props: {
            item: {
                // 传入数据
                type: Object,
                default: () => {
                },
            },
        },
        watch: {
            goodsValue(val) {
                this.pickerkeywordInput("goods", val);
            },
            supValue(val) {
                this.pickerkeywordInput("sup", val);
            },
        },
        mounted() {
            if (!utils.isWechat()) {
                this.navBarShow = true;
            }
            // this.id = this.$route.query.id;
            // this.goodsValue = this.$route.query.goods;
            // this.supValue = this.$route.query.supplier;
        },
        methods: {
            toggleShow() {
                // 询盘框显示隐藏
                this.show = !this.show;
            },
            doEnquiry() {
                let that = this;
                that.loadFlag = true;
                // 发布询盘
                this.$http
                    .post("/v1/doEnquiry", {
                        id: this.id,
                        goods: this.goodsValue,
                        supplier: this.supValue,
                        goods_spec: this.goods_spec,
                        number: this.number,
                        certfile: this.certfile,
                        product_document: this.product_document,
                        packing: this.packing,
                        trade_method_price: this.trade_method_price,
                        trade_method_method: this.trade_method_method,
                        trade_method_port: this.trade_method_port,
                        special: this.special,
                        auto:this.auto,
                    })
                    .then((res) => {
                        that.loadFlag = false;
                        if (res.code == 200) {
                            Toast(this.$t("发布成功"));
                            //this.toggleShow();
                        }
                    });
            },
            poolSup() {
                //  供应商列表
                this.$http.post("/v1/poolSup").then((res) => {
                    if (res!=undefined&&res.code == 200) {
                        this.supList = res.data.list;
                    }
                });
            },
            poolGoods() {
                // 公共产品
                this.$http.post("/v1/poolGoods").then((res) => {
                    if (res!=undefined&&res.code == 200) {
                        this.goodsList = res.data.list;
                    }
                });
            },
            clearPicker(type) {
                // 清楚选择
                this[type + "Value"] = "";
            },
            selectPicker(type, value) {
                // 选择下拉项
                this[type + "Value"] = value;
                this[type + "Columns"] = [];
                this[type + "Show"] = false;
            },
            pickerkeywordInput(type, val) {
                //  关键字变化事件
                if (val) {
                    let url = "";
                    switch (type) {
                        case "goods":url = "/v1/poolGoods";break;
                        case "sup":url = "/v1/poolSup";break;
                    }
                    this.$http.post(url,{val:val}).then((res) => {
                        if (res.code == 200) {
                            let columns = res.data.list.filter(
                                (item) => item && item.toLowerCase().indexOf(val.toLowerCase()) != -1
                            );
                            this[type + "Columns"] = columns;
                        }
                    });
                } else {
                    this[type + "Columns"] = [];
                }
            },
        },
    };
</script>

<style scoped>
    .transaction-search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding-bottom: 5vw;
        overflow: auto;
    }

    .transaction-search-no-wechat {
        top: 46px !important;
        height: calc(100vh - 46px);
    }

    .placeholder-title {
        color: #ccc;
    }

    .transaction-search-btn {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        width: 100vw;
        height: 40px;
        box-sizing: border-box;
    }

    .picker-main {
        position: absolute;
        top: 33px;
        right: 0;
        width: 100%;
        border: 1px solid #ccc;
        z-index: 1;
        background: rgb(0,0,0,0.6);
        border-radius: 3px;
        max-height: 75vh;
        overflow: auto;
        color:#FFF;
    }

    .picker-list {
        text-align: left;
        padding: 3px 10px;
        border-bottom: 1px solid #e1e1e1;
    }

    .van-cell {
        overflow: initial !important;
    }

    .more-title {
        text-align: center;
        margin-top: 5vw;
        color: royalblue;
    }

    .van-radio--horizontal {
        margin: 3px 5px;
    }
    .blank-box{
        min-height: 50px;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>