<template>
    <div
            v-if="show"
            class="transaction-search"
            :class="navBarShow ? 'transaction-search-no-wechat' : ''"
    >
        <van-cell-group>
            <van-cell :title="$t('产品')" :value="info.pg_name" />
            <van-cell :title="$t('规格')" :value="info.spec" />
            <van-cell :title="$t('数量')" :value="info.number" />
            <van-cell :title="$t('价格')" :value="info.price" />
            <van-cell :title="$t('交易方式')" :value="info.tm_arr.price?info.tm_arr.price:''" />
            <van-cell :title="$t('交易方式')" :value="info.tm_arr.method?info.tm_arr.method:''" />
            <van-cell :title="$t('港口')" :value="info.tm_arr.port?info.tm_arr.port:''" />
            <van-cell :title="$t('其他信息')" :value="info.memo" />
        </van-cell-group>

        <van-overlay :show="loadFlag">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="34px" color="#FFFFFF" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import utils from "../utils/utils";

    export default {
        name: "GoodsPriceDetail",
        data() {
            return {
                id:0,
                info:{},
                show: false, // 搜索框显示
                loadFlag:true,//加载层
            };
        },
        props: {

            item: {
                // 传入数据
                type: Object,
                default: () => {
                },
            },
        },
        watch: {
            item(val) {
                this.id = val.id;
            },
            id(val){
                //填充数据
                if(val!=0){
                    this.initData();
                }
            },
        },
        mounted() {
            if (!utils.isWechat()) {
                this.navBarShow = true;
            }
        },
        methods: {
            toggleShow() {
                // 询盘框显示隐藏
                this.show = !this.show;
            },
            initData(){
                let that = this;
                that.loadFlag = true;
                this.$http.post("/v1/getPriceInfo",{id:this.id}).then((res) => {
                    if (res.code == 200) {
                        let info = res.data.info;
                        that.info = info;

                        that.loadFlag = false;
                        // console.log(that.multCus);
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .transaction-search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding-bottom: 5vh;
        overflow: auto;
    }

    .transaction-search-no-wechat {
        top: 46px !important;
        height: calc(100vh - 46px);
    }
    .transaction-search-btn {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        width: 100vw;
        height: 40px;
        box-sizing: border-box;
    }
    .placeholder-title {
        color: #ccc;
    }
    .login-right {
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* box-shadow: 0 0 15px 0 #ccc; */
    }
    .taglibs {
        align-content: center;
        flex-direction: column;
    }

    .taglibs-item {
        padding: 5px 4px;
    }


    .van-cell {
        overflow: initial !important;
    }

    .more-title {
        text-align: center;
        margin-top: 5vw;
        color: royalblue;
    }
    .taglibs {
        align-content: center;
        flex-direction: column;
    }

    .taglibs-item {
        padding: 5px 4px;
    }
    .custome-box{
        padding-bottom: 50px;
        min-height: 10vh;
    }
    .van-tag{
        margin: 2px 5px;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>